import React, { Component } from "react"
import styled from "styled-components"

export default class Timer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      milliseconds: 0,
      seconds: 0,
      minutes: 0,
      hours: 0,
      status: null
    }
    this.interval = null
  }

  componentDidMount() {
    if (this.props.unixtimestamp) this._startTimer()
  }

  componentWillUnmount() { 
    clearInterval(this.interval) 
  }

  componentDidUpdate(prevProps) {
    // console.log("CDU  ", this.props, this.state)
    // console.log("CDU  PrevProps", prevProps)
    // console.log("condition for CDU: ", prevProps.status !== this.props.status)
    if (prevProps.unixtimestamp !== this.props.unixtimestamp) this._startTimer()
    if (prevProps.status !== this.props.status) this._updateStatus()
  }

  _updateStatus = () => {
    return this.props.status === null
      ? ""
      : this.props.status
      ? "Blocked time "
      : "clear for "
  }

  _startTimer = () => { 
    clearInterval(this.interval) 
    this.interval = setInterval( () => {
      const startTimeInSeconds = Math.floor(this.props.unixtimestamp / 1000)
      const nowInSeconds = Math.floor(new Date() / 1000)
      const totalSecondsDifference = nowInSeconds - startTimeInSeconds
      const seconds = totalSecondsDifference % 60
      const minutes = Math.floor(totalSecondsDifference / 60) % 60
      const hours = Math.floor(totalSecondsDifference / 3600)
      this.setState({ hours, minutes, seconds })
    }, 1000)
  }

  render() {
    const { seconds, minutes, hours } = this.state
    return (
      <TimerDiv>
        <TimerHeader hours={hours} >Blocked Time</TimerHeader>
        <TimerText>
          {hours
            ? `${hours}hrs ${minutes}mins ${seconds}secs`
            : `${minutes}mins ${seconds}secs`}
        </TimerText>
      </TimerDiv>
    )
  }
}

const TimerDiv = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
`

const TimerHeader = styled.p`
  font-family: Aktiv Grotesk, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  align-self: flex-start;
  color: #ffffff;
  opacity: 0.5;
  margin: 0;
`

const TimerText = styled.p`
  margin: auto;
  color: #fff;
  font-family: Aktiv Grotesk, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
`
