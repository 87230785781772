import React from 'react'
import styled from 'styled-components'
import { animated } from 'react-spring/renderprops'

const Header = ({trainstatus, className}) => {
  return (
    <HeaderContainer className={className}>
      <HeaderText trainstatus={trainstatus}>Is a train blocking 11th?</HeaderText>
    </HeaderContainer>
  )
}

const HeaderContainer = styled(animated.div)`
  white-space: nowrap;
`

const HeaderText = styled(animated.p)`
  font-family: Aktiv Grotesk, sans-serif;
  font-size: 30px;
  font-weight: 200;
  color: ${({trainstatus}) => trainstatus ? 'white' : 'black'};
  @media screen and (max-width: 350px) {
    font-size: 20px;
  }
`
export default Header;