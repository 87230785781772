import React, { Component } from "react"
import styled, { createGlobalStyle } from "styled-components"
import Firebase from "../firebase"
import { Spring, animated } from "react-spring/renderprops"
import { Helmet } from "react-helmet"

import Header from "../components/Header"
import Timer from "../components/Timer"
import { Footer } from "../components/Footer"
import SEO from "../components/seo"

export default class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      trainStatus: null,
      unixtimestamp: null,
      dataReturned: null,
    }
  }

  componentDidMount() {
    const fire = Firebase
    fire.db.ref("/train_right_now").on("value", snapshot => {
      this._updateTrainStatus(snapshot.val())
    })
    fire.db.ref("/last_updated").on("value", snapshot => {
      this._updateunixtimestamp(snapshot.val())
    })
    this.setState({ dataReturned: true })
  }

  _renderYes = () => {
    const { trainStatus } = this.state
    return (
      <Spring
        config={{ duration: 500 }}
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
      >
        {styles => (
          <>
            <BodyContainer>
              <PageHeader style={styles} trainstatus={trainStatus.toString()} />
              <TrainVerdict
                style={styles}
                trainverdictstring={"YES"}
                trainstatus={trainStatus.toString()}
              >
                {"YES"}
              </TrainVerdict>
              <TimerContainer style={styles}>
                {trainStatus === true && (
                  <Timer
                    style={styles}
                    unixtimestamp={this.state.unixtimestamp}
                    status={this.state.trainStatus}
                  />
                )}
              </TimerContainer>
            </BodyContainer>
          </>
        )}
      </Spring>
    )
  }

  _renderNo = () => {
    const { trainStatus } = this.state
    return (
      <Spring
        config={{ duration: 500 }}
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
      >
        {styles => (
          <>
            <BodyContainer style={styles}>
              <PageHeader style={styles} trainStatus={trainStatus.toString} />
              <TrainVerdict
                style={styles}
                trainverdictstring={"NO"}
                trainstatus={trainStatus.toString()}
              >
                {"NO"}
              </TrainVerdict>
              <TimerContainer style={styles}>
                {trainStatus === true && (
                  <Timer
                    style={styles}
                    unixtimestamp={this.state.unixtimestamp}
                    status={this.state.trainStatus}
                  />
                )}
              </TimerContainer>
            </BodyContainer>
          </>
        )}
      </Spring>
    )
  }

  _renderMaintenance = () => {
    return (
      <>
        <BodyContainer>
          <PageHeader trainStatus={true} />
          <MaintenanceContainer>
            <p>
              We need your help. We’re no longer in our office space overlooking
              11th and Division, and need to find a new spot for our traffic
              detection setup.
            </p>
            <p>The traffic detection system requires: </p>
            <ul>
              <li>
                A good view of 11th, ideally south of Division and north of the
                train tracks
              </li>
              <li>Wifi connection</li>
              <li>Power outlet</li>
              <li>Some sort of shelf/ledge to set the webcam/computer on</li>
            </ul>
            <p>
              If y’all can help us find a new spot to set up, we can start
              getting the website back up and running. Please send any hot tips
              to{" "}
              <a href="mailto:hottips@isatrainblocking11th.com">
                hottips@isatrainblocking11th.com
              </a>
              . Thanks so much!
            </p>
          </MaintenanceContainer>
        </BodyContainer>
      </>
    )
  }

  _updateTrainStatus = trainStatus => this.setState({ trainStatus })

  _updateunixtimestamp = unixtimestamp =>
    this.setState({ unixtimestamp: unixtimestamp })

  render() {
    // const { trainStatus } = this.state // TODO: Uncomment this out when site is live again
    const trainStatus = false
    const underMaintenance = true
    return (
      <PageContainer>
        <SEO title={"Main"} />
        <GlobalStyle trainStatus={trainStatus} />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Is A Train Blocking 11th?</title>
          <link rel="canonical" href="http://isatrainblocking11th.com/" />
        </Helmet>
        {/* {underMaintenance
          ? this._renderMaintenance()
          : trainStatus === true
          ? this._renderYes()
          : trainStatus === false
          ? this._renderNo()
          : null} */}
        {this._renderMaintenance()}
        <FooterContainer>
          <Footer
            trainStatus={trainStatus}
            backgroundColor={trainStatus ? "black" : "white"}
          />
        </FooterContainer>
      </PageContainer>
    )
  }
}

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ trainStatus }) => (trainStatus ? "black" : "white")};
    height: 100vh;
    width: 100vw;
    padding: 0;
    margin: 0;
  }
`
const PageContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(6, 1fr);

  @media screen and (min-width: 760px) {
    /**Desktop Media Query */
  }
`

const BodyContainer = styled(animated.div)`
  grid-column: 1 / -1;
  grid-row: 1 / 5;
  width: fit-content;
  height: fit-content;
  display: grid;
  justify-items: left;
  align-content: stretch;
  margin: 0;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto 1fr auto;
  @media screen and (min-width: 760px) {
    /**Desktop Media Query */
    height: fit-content;
    width: auto;
    grid-column: 2 / 5;
    grid-row: 2 / 5;
    grid-gap: 10px;
  }
`

const PageHeader = styled(Header)`
  padding-left: 15px;
  width: 100%;
  height: fit-content;
  text-align: left;
  align-self: left;
  justify-self: left;
  margin: 0;
  grid-column: 1 / 3;
  grid-row: 1;
  @media screen and (min-width: 760px) {
    /**Desktop Media Query */
    grid-column: 2 / 3;
    grid-row: 1;
    height: auto;
    width: auto;
    padding-left: 0;
    align-self: start;
    justify-self: start;
  }
`

const TrainVerdict = styled(animated.p)`
  padding-left: 15px;
  margin: 0;
  letter-spacing: 3px;
  font-family: Aktiv Grotesk, sans-serif;
  font-size: 160px;
  color: ${({ trainstatus }) => (trainstatus === "true" ? "white" : "black")};
  align-self: start;
  justify-self: start;
  grid-column: 1 / 5;
  grid-row: 2;
  text-indent: ${({ trainverdictstring }) =>
    trainverdictstring === "YES" ? null : "-3.75%"};
  @media screen and (min-width: 760px) {
    /**Desktop Media Query */
    grid-column: 2 / -1;
    grid-row: 2;
    height: fit-content;
    padding-left: 0;
    align-self: start;
    justify-self: start;
  }
  @media screen and (max-width: 350px) {
    font-size: 130px;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    /**Safari Indentation Fix */
    @media {
      text-indent: ${({ trainverdictstring }) =>
        trainverdictstring === "YES" ? null : "-1.9%"};
      @media screen and (min-width: 760px) {
        /**Safari Indentation Desktop Fix */
        text-indent: ${({ trainverdictstring }) =>
          trainverdictstring === "YES" ? null : "-1.05%"};
      }
    }
  }
`

const TimerContainer = styled(animated.div)`
  padding-left: 15px;
  justify-self: left;
  align-self: start;
  grid-column: 1 / 5;
  grid-row: 3;
  @media screen and (min-width: 760px) {
    /**Desktop Media Query */
    grid-column: 2 / -1;
    grid-row: 3;
    padding-left: 0;
    justify-self: left;
    align-self: start;
    text-align: left;
  }
`

const MaintenanceContainer = styled.div`
  font-family: Aktiv Grotesk, sans-serif;
  font-size: 20px;
  letter-spacing: 1px;
  padding-left: 15px;
  justify-self: left;
  align-self: start;
  grid-column: -1 / 5;
  grid-row: 3;
  @media screen and (min-width: 760px) {
    /**Desktop Media Query */
    grid-column: 1 / -1;
    grid-row: 3;
    padding-left: 0;
    justify-self: left;
    align-self: start;
    text-align: left;
  }
`

const FooterContainer = styled.div`
  grid-column: 1 / -1;
  grid-row: 6;
  align-self: end;
  justify-self: end;
  align-items: end;
  bottom: 0;
  width: 100%;
  position: sticky;
  @media screen and (min-width: 760px) {
    grid-row: 1 / 2;
    align-self: stretch;
  }
`
